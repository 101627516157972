import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { PowderCircle, CocoaCircle } from '../../../../content/assets/icons'

const variants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
}

const Dropdown = ({ subLinks }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    className="absolute -m-8 transform -translate-x-1/2 mt-3 px-2 w-48 sm:px-0"
  >
    <div className="rounded-lg shadow-lg">
      <div className="rounded-lg shadow-xs overflow-hidden">
        <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          {subLinks.map(subLink => {
            return (
              <Link
                className="-m-3 p-3 block space-y-1 rounded-md transition ease-in-out duration-150"
                key={subLink.to}
                to={subLink.to}
              >
                <div className="flex flex-row justify-start items-center">
                  <div className="h-6 w-6">
                    {subLink.color === 'powder' ? (
                      <PowderCircle />
                    ) : (
                      <CocoaCircle />
                    )}{' '}
                  </div>
                  <p className="ml-4 text-base leading-6 font-normal text-cocoa hover:text-yellow-800 focus:outline-none focus:text-yellow-800">
                    {subLink.link}
                  </p>
                  {/* <p className="text-sm leading-5 text-gray-500">
                    Learn about tips, product updates and company culture.
                  </p> */}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  </motion.div>
)

export default Dropdown

Dropdown.propTypes = {
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
}
