import React from 'react'

const Heading2 = props => {
  return (
    <h2
      className="mt-5 text-3xl leading-9 tracking-tight font-light text-cocoa sm:text-3xl sm:leading-10"
      {...props}
    />
  )
}

export default Heading2
