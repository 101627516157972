import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropdown from './dropdown'

const NavLinkDropdown = ({ link, subLinks }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible)
  // const hideDropdown = () => setDropdownVisible(false)

  return (
    // <div onClick={toggleDropdown} onBlur={hideDropdown} className="relative">
    <div onClick={toggleDropdown} className="relative">
      {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
      <button
        type="button"
        aria-label={`${link} Menu`}
        className="group text-cocoa inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-yellow-800 focus:outline-none focus:text-yellow-800 transition ease-in-out duration-150"
      >
        <span>{link}</span>
        {/* <!-- Item active: "text-gray-600", Item inactive: "text-gray-400" --> */}
        <svg
          className="text-cocoa h-5 w-5 group-hover:text-yellow-700 group-focus:text-yellow-700 transition ease-in-out duration-150"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {dropdownVisible ? <Dropdown subLinks={subLinks} /> : null}
    </div>
  )
}

export { NavLinkDropdown }

NavLinkDropdown.propTypes = {
  link: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
}
