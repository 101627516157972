/* eslint-disable react/prop-types */
import React from 'react'

const PowderCircle = () => (
  <svg
    data-name="PowderCircle"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 360"
  >
    <circle cx="179.39" cy="180.3" r="178.2" fill="#f3ddce" />
  </svg>
)

export { PowderCircle }
