/* eslint-disable react/prop-types */
import React from 'react'

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Linds Makes Logo"
    viewBox="0 0 403.2 403.2"
  >
    <circle cx="201.65" cy="202.58" r="198.39" fill="#af6e3e" />
    <path
      fill="#f3ddce"
      d="M123.34 261.7a20.79 20.79 0 001.31 8.3c1.18 2.49 3 3.67 5.09 3.67 17.42 0 62.1-71.69 62.21-71.93 1.19-1.78 2.49-2.49 3.56-2.49 1.3 0 2.25.95 2.25 2.61v.14c-.12.24-46.93 73.35-68.37 73.35-3.2 0-5.81-1.66-7.71-5.45-1.18-2.49-1.66-6.4-1.66-11.14 0-31.05 23-102.62 38.75-124.31a116.39 116.39 0 0110.79-15.76c3.67-4.15 7-6.51 9.71-6.51h.12c2 .11 3.56 1.42 4.51 3.91a13.6 13.6 0 01.71 4.62c0 21.33-33.78 71.33-48.11 80.93-8.77 28.07-13.16 48.57-13.16 60.06zM180.1 119a4.37 4.37 0 00-.23-1.42c-.36-.72-.6-1.07-.83-1.07h-.12c-1.19 0-3.32 1.9-6 5a103.25 103.25 0 00-10.31 15c-7.35 12.56-16.83 38.75-22.4 54.39 7.31-6.7 39.89-59.31 39.89-71.9z"
    />
    <path
      fill="#f3ddce"
      d="M196 226.63c0 .59.12.94.48.94a.79.79 0 00.59-.23c18.72-23.82 28.32-48.11 39.81-48.11a10.34 10.34 0 014.39 1.06 7.19 7.19 0 012.84 5.93c0 4.62-3 11-6.51 21.92-2 4.15-5.22 9.84-5.22 10.78l.12.12c1.42 0 13.51-16 20.86-16h.59c9 3.44 3.67 21.22 13.86 21.22a9.5 9.5 0 001.78-.12c5.93-.95 21.8-12.56 30.69-24.41a2.62 2.62 0 012.37-1.31c1.78 0 3.67 1.54 3.67 2.85a1.73 1.73 0 01-.23.83C294 215 276.44 225 269.69 226a16 16 0 01-2.25.24c-11.74 0-11.14-17.66-14.7-17.66-.23 0-.35.12-.59.24-8.29 4.39-17.54 20.86-22.63 21.45a14.14 14.14 0 01-2.25.12c-3.2 0-6.28-1.07-6.28-4.62a7.87 7.87 0 01.35-2.25c0-.12 14.69-30.7 14.69-39.23a2.56 2.56 0 00-.47-1.89h-.47c-8.3 0-29.27 49-41.72 54.86-4.14-.12-6.87-.59-6.87-6.52a41.65 41.65 0 011.3-9.24c4.39-17.54 11-37 12.68-40.41.36-2.25 3.68-3.43 6.64-3.43 2.61 0 5 .82 5 2.6 0 .24-.12.48-.12.71 0 .03-16 39.03-16 45.66z"
    />
  </svg>
)

export { Logo }
