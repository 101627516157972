/* eslint-disable react/prop-types */
import React from 'react'
import SocialLinks from './socialLinks'

const Footer = () => {
  return (
    <footer>
      <div className="bg-powder">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="md:hidden flex justify-center md:order-2">
            <SocialLinks />
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6 text-cocoa">
              &copy; {new Date().getFullYear()}, Linds Makes | Made with Love
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
