/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import {
  Logo,
  CocoaCircle,
  PowderCircle,
} from '../../../../content/assets/icons'
import SocialLinks from '../socialLinks'

const variants = {
  hidden: { duration: 2, opacity: 0, scale: 0.95 },
  visible: { duration: 1, opacity: 1, scale: 1 },
}

const MobileMenu = ({ toggleMenu, subLinks }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
    >
      <div className="rounded-lg shadow-lg">
        <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5 space-y-6">
            <div className="flex items-center justify-between">
              <div className="h14 w-14">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={() => toggleMenu()}
                  aria-label="Close Menu"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <nav className="grid row-gap-8">
                {subLinks.map(subLink => {
                  return (
                    <Link
                      className="-m-3 p-3 block space-y-1 rounded-md transition ease-in-out duration-150"
                      key={subLink.to}
                      to={subLink.to}
                    >
                      <div className="flex flex-row justify-start items-center">
                        <div className="h-6 w-6">
                          {subLink.color === 'powder' ? (
                            <PowderCircle />
                          ) : (
                            <CocoaCircle />
                          )}
                        </div>
                        <p className="ml-3 text-base leading-6 font-normal text-cocoa hover:text-yellow-800 focus:outline-none focus:text-yellow-800">
                          {subLink.link}
                        </p>
                        {/* <p className="text-sm leading-5 text-gray-500">
                    Learn about tips, product updates and company culture.
                  </p> */}
                      </div>
                    </Link>
                  )
                })}
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 flex justify-center md:order-2">
            <SocialLinks />
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export { MobileMenu }

MobileMenu.propTypes = {
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
}
