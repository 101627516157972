/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Logo } from '../../../content/assets/icons'
import {
  MobileMenu,
  HamburgerMenu,
  NavLink,
  NavLinkDropdown,
} from './headerComponents'
import SocialLinks from './socialLinks'

const MAKE_SUBLINKS = [
  { to: '/bake', link: 'BAKE', color: 'powder' },
  { to: '/craft', link: 'CRAFT', color: 'cocoa' },
  { to: '/design', link: 'DESIGN', color: 'powder' },
  { to: '/play', link: 'PLAY', color: 'cocoa' },
  { to: '/cook', link: 'COOK', color: 'powder' },
]

const MOBILE_SUBLINKS = [
  { to: '/bake', link: 'BAKE', color: 'powder' },
  { to: '/craft', link: 'CRAFT', color: 'cocoa' },
  { to: '/design', link: 'DESIGN', color: 'powder' },
  { to: '/play', link: 'PLAY', color: 'cocoa' },
  { to: '/cook', link: 'COOK', color: 'powder' },
  { to: '/shop', link: 'SHOP', color: 'cocoa' },
]

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <header className="relative">
      <div className="absolute z-10 inset-x-0 top-0 mx-auto mt-10 md:mt-5 w-24 h-24 md:w-40 md:h-40">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="relative bg-powder shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
              <nav className="flex space-x-10">
                <NavLinkDropdown link="MAKE" subLinks={MAKE_SUBLINKS} />
                <NavLink to="/shop" link="SHOP" />
              </nav>
              <div className="hidden md:flex justify-center md:order-2">
                <SocialLinks />
              </div>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <HamburgerMenu toggleMenu={toggleMenu} />
            </div>
          </div>
        </div>

        {showMobileMenu ? (
          <MobileMenu toggleMenu={toggleMenu} subLinks={MOBILE_SUBLINKS} />
        ) : null}
      </div>
    </header>
  )
}

export default Header
