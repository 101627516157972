import React from 'react'
import { Facebook, Instagram } from '../../../content/assets/icons'

const SocialLinks = () => {
  return (
    <div className="flex justify-between">
      <a
        href="https://www.facebook.com/lindsmakes"
        className="text-cocoa hover:text-gray-500"
      >
        <span className="sr-only">Facebook</span>
        <Facebook />
      </a>
      <a
        href="https://www.instagram.com/lindsmakes/"
        className="ml-6 text-cocoa hover:text-gray-500"
      >
        <span className="sr-only">Instagram</span>
        <Instagram />
      </a>
    </div>
  )
}

export default SocialLinks
