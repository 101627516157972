import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const NavLink = ({ to, link }) => {
  return (
    <Link
      to={to}
      className="text-base leading-6 font-medium text-cocoa hover:text-yellow-800 focus:outline-none focus:text-yellow-800 transition ease-in-out duration-150"
    >
      {link}
    </Link>
  )
}

export { NavLink }

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}
