/* eslint-disable react/prop-types */
import React from 'react'
import Header from './layout/header'
import HeaderSmall from './layout/header-small'
import Footer from './layout/footer'

const Layout = ({ location, title, children }) => {
  // eslint-disable-next-line no-undef
  // const rootPath = `${__PATH_PREFIX__}/`
  let header

  // if (location.pathname === rootPath) {
  header = <Header title={title} />
  // } else {
  //   header = <HeaderSmall title={title} />
  // }

  return (
    <div className="flex flex-col min-h-screen">
      {header}
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
