/* eslint-disable react/prop-types */
import React from 'react'

const CocoaCircle = () => (
  <svg
    data-name="Cocoa Circle"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 360"
  >
    <circle cx="179.39" cy="180.3" r="178.2" fill="#af6e3e" />
  </svg>
)

export { CocoaCircle }
